.jumbotron {
  background: $primary-color;
  color : white !important;
  .display-4 {
    font-weight: 400;
    color : white !important;

  }
}

.banner_offer {
  border-width:10px !important;
  border-color: $primary-color !important;
}

.parallax{
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    position:relative;

}

.banner_background {
  background: #797970d1;
}
@media only screen and (max-device-width: 1024px) {
    .parallax {
        background-attachment: scroll;
    }
}

#header{
 position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
