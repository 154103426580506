
ul#markdown-toc,ol#markdown-toc {
  list-style-type: none;
  margin : 0;
  padding : 0 20px 0 20px;
  border-style: solid;
  border-width: 1px;
  li {
    margin : 15px 0 15px 0;

  }
}

@media screen and (max-width: 770px) {
    //font-size: 15px;
    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 18px;
    }
    h5 {
      font-size: 16px;
    }
    h6 {
      font-size: 15px;
    }
}
