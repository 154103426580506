body,
html,
div,
header,
footer,
nav,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $base-font-weight;
    color:  #2a303b;

}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong {
    color:  #2B2B2B;

}

html {
    height: 100%;
    scroll-behavior: smooth;
}
body {
    font-family: $base-font-family;
    font-size : $base-font-size;
    color: $text-color;
    background-color: $background-color;
    position: relative;
    min-height: 100%;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
}
a {
    text-decoration: none;
}
button {
    border: none;
    outline: none;
}
hr {
    border: 0;
    border-top: 1px solid #eee;
    margin: 20px 0;
}

///////////
h1 {
    font-size: 40px;
    margin: 50px 0 25px;
}
h2 {
    font-size: 36px;
    margin: 52px 0 28px;
}
h3 {
    font-size: 28px;
    margin: 50px 0 20px;
}
h4 {
    font-size: 19px;
    margin: 30px 0 10px;
}
h5 {
    font-size: 17px;
    margin: 20px 0 10px;
}
h6 {
    font-size: 16px;
    margin: 10px 0 5px;
}


blockquote {
  margin: 30px 0 30px;
  padding: 1em;
  border-left: 10px solid $primary-color;
}

blockquote:before {
  display: none;
}
blockquote:not(:first-of-type) {
  margin-top: .5em;
}
blockquote p {
  color: #555;
  font-size: 23px;
  line-height: 1.4;
  font-family: 'PT Serif', Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
}
blockquote footer {
  margin-top: .5em;
  padding: 0;
  color: #777;
  font-size: 23px;
  text-align: left;
  font-style: italic;
}
blockquote footer:before {
  content: '— ';
}
blockquote:nth-of-type(even) {
  margin: 30px 0 30px;
  padding: 1em;
  text-align: right;
  border-left: none;
  border-right: 10px solid $primary-color;
}

blockquote:nth-of-type(even) footer {
  text-align: right;
}
blockquote:nth-of-type(even) footer:before {
  content: '';
}
blockquote:nth-of-type(even) footer:after {
  content: ' —';
}
@element 'blockquote' and (min-width: 300px) {
  blockquote {
    padding: 1em 20% 1em 1em;
  }
  blockquote p {
    font-size: 23px;
  }
  blockquote:nth-of-type(even) {
    padding: 1em 1em 1em 20%;
  }
}

table {
    margin: 8px 0;
    border-collapse: collapse;
    width: 100%;
}
p {
    margin-top: $base-padding-vertical;
    margin-bottom: $base-padding-vertical;
    margin-left: 0;
    margin-right: 0;
}
img {
    max-width: 100%;
}

.jumbotron {
  background: $primary-color;
}
.green {
  color : green;
}

.red {
  color : red;
}

big.amount {
  font-size : 3rem;
  font-weight : 700;
}

.link_color {
  color : $link-color;
}

p > a {
  font-size: 20px;
  font-weight : bold;

  &:hover {
    text-decoration: underline;
  }

}
