
.article_link {
  img.img-thumbnail {
      height: 150px;
      width : 150px;
    }
}


@media screen and (max-width: 770px) {

  .article_link {
    img.img-thumbnail {
        height: 104px;
        width : 104px;
      }
  }

}
