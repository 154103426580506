#offer_number {
  font-weight: 500;
}

.section_header {
  color: white;
  background: $primary-color;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
