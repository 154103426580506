footer {

  margin-top: 70px;
  margin-bottom: 20px;


  li {
    padding: 2px 0px 2px 0px;
  }



  a {
    color : black;
    &:hover {
        background-color: white;
        color: $primary-color
    }
  }






}
