

#carouselExampleFade {

  .card-img-top {
    width: 100vw;
    height: 40vh;
    // object-fit: cover;
  }
  
  .quizz_header {
    color: white;
    background: $primary-color;
    padding: 15px 20px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  fieldset {
      border-color: $primary-color;
  }

  legend {
      font-size: 105%;
      font-weight: 600;
      padding-top: 15px;
  }

  /* Customize the label (the box) */
  .box {
    display: block;
    position: relative;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
    font-size: $base-font-size  ;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .box input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: relative  ;
    top: 0;
    left: 0;
    height: 25px ;
    width: 25px ;
    min-width: 25px;
    min-height: 25px;
    margin : 0px 10px 0px 0px;
    background-color: #eee;
    border-radius : 50% ;
  }


  /* On mouse-over, add a grey background color */
  .box:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .box input:checked ~ .checkmark {
    background-color: $primary-color;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .box input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .box .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }

  label:hover   {
    font-weight: bold;
  }

}

.result {
  .card-img-top {
    width: 100vw;
    height: 40vh;
    // object-fit: cover;
  }
}
