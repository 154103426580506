@charset  "utf-8";


// Our variables
$base-font-family: Georgia,sans-serif;

$base-font-size:   18px;
$base-font-weight: 400;
$base-padding-vertical: 20px;



$primary-color : #3E5062;
$text-color:  #1c1e21;
$background-color: white;
$link-color: #026b9c;
$line-height: 1.6;


* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.item {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.item img {
  max-width: 100%;

  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.item:hover img {
  -moz-transform: scale(1.15);
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
  opacity: 1

}
/*
 * variables
 */
// Import partials from `sass_dir` (defaults to `_sass`)
@import
"reset",
"navbar",
"post",
"index",
"footer",
"textonimage",
"author",
"banner",
"bonus_offer",
"share-bar",
"article_link",
"multicarousel",
"jquery.gScrollingCarousel",
"star",
"youmax",
"quizz";
