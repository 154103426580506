@media (min-width: 768px) {

    #carouselYoutube {
      
      /* show 3 items */
      .carousel-inner .active,
      .carousel-inner .active + .carousel-item,
      .carousel-inner .active + .carousel-item + .carousel-item,
      .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item  {
          display: block;
      }

      .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left),
      .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item,
      .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item,
      .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
          transition: none;
      }

      .carousel-inner .carousel-item-next,
      .carousel-inner .carousel-item-prev {
        position: relative;
        transform: translate3d(0, 0, 0);
      }

      .carousel-inner .active.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: absolute;
          top: 0;
          right: -25%;
          z-index: -1;
          display: block;
          visibility: visible;
      }

      // fix for bootstrap 4
      .carousel-item {margin-right: 0 !important;}


      /* left or forward direction */
      .active.carousel-item-left + .carousel-item-next.carousel-item-left,
      .carousel-item-next.carousel-item-left + .carousel-item,
      .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item,
      .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item,
      .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(-100%, 0, 0);
          visibility: visible;
      }

      /* farthest right hidden item must be abso position for animations */
      .carousel-inner .carousel-item-prev.carousel-item-right {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          display: block;
          visibility: visible;
      }

      /* right or prev direction */
      .active.carousel-item-right + .carousel-item-prev.carousel-item-right,
      .carousel-item-prev.carousel-item-right + .carousel-item,
      .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item,
      .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item,
      .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
          position: relative;
          transform: translate3d(100%, 0, 0);
          visibility: visible;
          display: block;
          visibility: visible;
      }

    }

}

 /* Bootstrap Lightbox using Modal */

#profile-grid { overflow: auto; white-space: normal; }
#profile-grid .profile { padding-bottom: 40px; }
#profile-grid .panel { padding: 0 }
#profile-grid .panel-body { padding: 15px }
#profile-grid .profile-name { font-weight: bold; }
#profile-grid .thumbnail {margin-bottom:6px;}
#profile-grid .panel-thumbnail { overflow: hidden; }
#profile-grid .img-rounded { border-radius: 4px 4px 0 0;}
