/* Share Bar */
#share-bar {
    font-size: 20px;
}

/* Title */
#share-bar h4 {
    margin-bottom: 10px;
    font-weight: 500;
}

/* All buttons */
.share-buttons {
}

/* Each button */
.share-button {
    margin: 0px;
    margin-bottom: 10px;
    margin-right: 0px;
    border: 1px solid #D3D6D2;
    padding: 5px 10px 5px 10px;
}
.share-button:hover {
    opacity: 1;
    color: #ffffff;
}

/* Facebook button */
.fa-facebook-f {
    color: #3b5998;
}
.fa-facebook-f:hover {
    background-color: #3b5998;
}

/* Twitter button */
.fa-twitter {
    color: #55acee;
}
.fa-twitter:hover {
    background-color: #55acee;
}


/* Pinterest button */
.fa-pinterest-p {
    color: #cb2027;
}
.fa-pinterest-p:hover {
    background-color: #cb2027;
}

/* Tumblr button */
.fa-tumblr {
    color: #32506d;
}
.fa-tumblr:hover {
    background-color: #32506d;
}

/* Reddit button */
.fa-reddit-alien {
    color: #ff4500;
}
.fa-reddit-alien:hover {
    background-color: #ff4500;
}

/* LinkedIn button */
.fa-linkedin {
    color: #007bb5;
}
.fa-linkedin:hover {
    background-color: #007bb5;
}
