:root {
  --star-size: $base-font-size;
  --star-color: gray;
  --star-background: #fc0;
}

.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  height: auto;
  font-size: var(--star-size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;

  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
