div.allsection {

  a.list-group-item {
    color : black;
  }

  a.list-group-item:hover {
    color: white;
    background-color: $primary-color;
  }

}

.section {
  border-width: 1px 0px 1px 0px;
  border-color : $primary-color;
  border-style: solid;
  padding :  48px 0px 48px 0;

}
.highcharts-credits {
  display: none;
}
.catchText {
  color : $primary-color;
}

.priceText {
 color : #6c6e80;
}

@media (min-width: 530px) {
  .projects-list .card-title {
      font-size: 1.5rem;
  }

  .projects-list .card-subtitle {
      font-size: 1.2rem;
  }
}

.projects-list .card-title {
    font-weight: 600;
    color:  #101c29;
}

.projects-list .card-subtitle {
    font-weight: 700;
    color: gray;
    font-size: 1.5rem;

}

img.opaque-low {
  opacity: 0.8;
}

img.opaque-low:hover {
  opacity : 1;
}

img.opaque {
  opacity: 0.4;
}

img.opaque:hover {
  opacity : 1;
}


a {
    color: $link-color;
    transition: 0.5s ease;

}

.imageCaption {
  color : #8b92a1;
}
a.unstyle {
  color : $text-color;
  text-decoration : none;
}


ul {
    li {
        color: $text-color;
        h2 {
            border: none;
            font-size: 27px;
            margin: 0;
        }

    }
}

ul:not(.no_margin),ol:not(.no_margin){
    margin: 25px 0 25px 40px  ;
    li {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}
.pagination {
  font-size: 25px;
  text-align: center;
    .previous,
    .next {
        padding: 0 5px;
        margin : 0 2px;
        color: white;
        background-color: $primary-color
    }
    .page_number {
        padding: 0 5px;
    }
    .disable {
        color: #cbcbcb;
        visibility: hidden
    }
}

.mask {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);

}
.companyLogo {
  position: absolute;
}


.excerpt {
  h1 {
    margin: 25px 0 25px;
  }
  h2 {
    margin: 28px 0 28px;
  }
  h3 {
    font-size: 3rem;
    margin: 20px 0 20px;
  }
  h4 {
    margin: 10px 0 10px;
  }
  h5 {
    margin: 10px 0 10px;
  }
  h6 {
    margin: 5px 0 5px;
  }
}

@media screen and (max-width: 770px) {

        ul li {
            h2 {
                font-size: 22px;
            }
            .excerpt {
                h2 {
                    font-size: 20px;
                }
                h3 {
                    font-size: 18px;
                }
                h4 {
                    font-size: 16px;
                }
                li{
                    margin: 0;
                }
            }
        }

}
