img#author {
  height: 152px;
  width: 152px;
  object-fit: cover;
}

img#authorAbout {
  height: 304px;
  width: 304px;
  object-fit: cover;
}

img.circleImage {
  border-radius: 50%;
}
