nav {

  color: $primary-color;

  .navbar-brand {
    font-weight:700;
    font-size: 30px;
    color: white;
  }

  button {
    color : black;
  }

  a.dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  a:not(.btn) {
    color : black;
    @media screen and (max-width: 770px) {
      font-size: $base-font-size + 3;
    }
    &:hover {
        background-color: white;
        color: $primary-color
    }
  }

  li.nav-item:hover {
    border-bottom: 4px solid $primary-color;
    margin-bottom : -4px;
  }

  .dropdown:hover>.dropdown-menu {
    display: block;
    padding-top: 1rem;

    a.dropdown-item:hover {
      background-color: $primary-color;
      color: white;
    }
  }

  .dropdown>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
      // pointer-events: none;
  }



}
